<template>
  <div class="p-p-3">
    <i class="pi pi-circle-on hf1 orange p-d-inline"></i>
    <div class="hf1 orange p-d-inline p-ml-3">Contact Us</div>
    <div class="card2 p-shadow-4 p-mt-3 p-p-3" style="color: white">
      <div>
        If you have any questions, please contact <b>info@bogorpools.com.</b>
      </div>
      <div>
        Or you can claim the form below. Please send comments and suggestions.
      </div>
      <Divider />
      <!--Name-->
      <div class="p-field p-grid p-fluid">
        <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
          Name
        </label>
        <div class="p-col-12 p-md-10">
          <InputText v-model="forms.name" id="name" type="text" />
        </div>
      </div>

      <!--Email-->
      <div class="p-field p-grid p-fluid">
        <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
          Email
        </label>
        <div class="p-col-12 p-md-10">
          <InputText v-model="forms.email" id="name" type="text" />
        </div>
      </div>

      <!--Subject-->
      <div class="p-field p-grid p-fluid">
        <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
          Subject
        </label>
        <div class="p-col-12 p-md-10">
          <InputText v-model="forms.subject" id="name" type="text" />
        </div>
      </div>

      <!--Message-->
      <div class="p-field p-grid p-fluid">
        <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
          Message
        </label>
        <div class="p-col-12 p-md-10">
          <Textarea
            v-model="forms.message"
            rows="5"
            cols="50"
            :autoResize="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      forms: {
        name: null,
        email: null,
        subject: null,
        message: null,
      },
    };
  },
};
</script>

<style>
</style>